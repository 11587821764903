import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { trackGa } from '@components/GoogleTracking'

import './diagnostic-expandible.component.scss'

class DiagnosticExpandibleComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isContentHidden: true,
    }

    this.expandibleRef = React.createRef()
  }
  render() {
    const content = this.props.collapsibleCardContent
    return (
      <div>
        {this.props.mobile ? (
          <div>
            <div className="diagnostic-expandible" ref={this.expandibleRef}>
              <div className="look-deeper-card">
                {this.props.collapsibleCardContent.title1}
                <div className={this.isExpanded(this.state.isContentHidden)}>
                  {content.srcContent}
                  {content.blueTitle1}
                  {content.content1}
                  {content.content2}
                  {content.blueTitle2}
                  {content.content3}
                  {content.content4}
                  {content.content5}
                  <div className="note-header">Notes:</div>
                  <div>{this.props.collapsibleCardContent.notes}</div>
                  <h3
                    className={this.isExpanded() ? 'look-deeper-card-expand' : 'collapsed'}
                    onClick={() => this.toggleExpansion()}
                  >
                    CLOSE DETAILS&nbsp;
                    <FontAwesomeIcon icon="compress" />
                  </h3>
                </div>

                <h3
                  htmlFor="expandible"
                  className={this.isClosed(this.state.isContentHidden)}
                  onClick={() => {
                    this.toggleExpansion()
                    trackGa(this.props.collapsibleCardContent.tracking)
                  }}
                >
                  READ DETAILS&nbsp;
                  <FontAwesomeIcon icon="expand" />
                </h3>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="diagnostic-expandible" ref={this.expandibleRef}>
              <div className="look-deeper-card">
                {content.title1}
                <div className="expanded">
                  {content.srcContent}
                  {content.blueTitle1}
                  {content.content1}
                  {content.content2}
                  {content.blueTitle2}
                  {content.content3}
                  {content.content4}
                  {content.content5}
                  <div className="note-header">Notes:</div>
                  {content.notes}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  isExpanded(isHidden) {
    return isHidden ? 'collapsed' : 'expanded'
  }

  isClosed(isHidden) {
    return isHidden ? 'look-deeper-card-expand' : 'hidden'
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isContentHidden && !prevState.isContentHidden) {
      window.scrollTo(window.scrollX, this.expandibleRef.current.offsetTop - 100)
    }
  }

  toggleExpansion() {
    this.setState({ isContentHidden: !this.state.isContentHidden })
  }
}

DiagnosticExpandibleComponent.propTypes = {
  collapsibleCardContent: PropTypes.object.isRequired,
  mobile: PropTypes.bool.isRequired,
}

export default DiagnosticExpandibleComponent
